import React from 'react'
import Carousel from 'react-bootstrap/Carousel'

export default function HomeCarousel() {
    return (
        <Carousel controls={false} fade={true} indicators={false}>
            <Carousel.Item>
                <Carousel.Caption className="home-text">
                    <h1>Text Goes<br />Here</h1>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <Carousel.Caption className="home-text">
                    <h1>More Text<br />Is here</h1>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>        
    )
}