import React from "react"
import { Link } from 'gatsby'

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import HomeCarousel from "../components/homeCarousel"
import Testimonials from "../components/testimonials"
import InstaHome from "../components/instafeed/home"

import { Container, Row, Col } from 'react-bootstrap'

export default function IndexPage() {
    return (
        <Layout>
            <SEO title="Home" />
            <div id="home">
              <HomeCarousel />
            </div>
            <Container className="pillar-container">
              <Row className="pillars">
                <Col className="bg-gold">
                  <h4>Detailing</h4>
                  <p>See our meticulous interior and exterior packages here</p>
                  <Link to="/detailing" className="ghost-button">Read More</Link>
                </Col>
                <Col className="bg-black white">
                  <h4>Paint Enhancement</h4>
                  <p>If you are looking to spruce up your dull, faded, or marred paint Bravo-1 has your back with our signature Paint Enhancement package!</p>
                  <Link to="/paint-enhancement" className="ghost-button">Read More</Link>
                </Col>
                <Col className="bg-gold">
                  <h4>Coating &amp; Wax</h4>
                  <p>See our coating options and their information here</p>
                  <Link to="/coating-wax" className="ghost-button">Read More</Link>                  
                </Col>
              </Row>
            </Container> 
            <Container id="about">
              <Row className="flex-height">
                <Col md="4">
                  <div className="circle-photo" />
                </Col>
                <Col>
                <p>Bravo-1 detailing specializes in full professional interior and exterior detailing, headlight restoration, polishing/ minor paint correction, engine bay detailing, ceramic coatings and more! Our goal is to ensure that your vehicle looks as good as it did the day you picked it up, and like in most of our cases, better!</p>
                <p>We take our time to clean every area meticulously and try our best to remove any stains, scuffs, and markings to bring back that “New” look and feel everytime!</p> 
                <p>We take great pride in our work and we believe fully in customer satisfaction, workmanship, and professionalism. We are a convenient mobile – only unit who can come to your house or even your work place, which means no waiting around for your vehicle or having to worry about a ride to and from a shop. You are able to relax, focus on work and get your daily tasks done around the house without worry!</p>
                <p><i><strong>Please note: We do not do any kind of express detailing as we are perfectionists and extremely meticulous.</strong></i></p> 
                </Col>
              </Row>
            </Container>
            <div className="wavey-parallax white">
              <div className="recent-projects">
                <Row className="center">
                  {/*<h2>Recent Projects</h2>
                  <p>Not only do we come to your location, but we treat your vehicle like our own. Take a deeper look into our process with our blog.</p>
                  <p><Link to="#" title="Show me the process" className="ghost-button">Lets See.</Link></p>*/}
                  <h2>Book Today</h2>
                  <p>Servicing the GTA and we come to your location!</p>
                  <p><Link to="/contact" title="Show me the process" className="ghost-button">Book Now.</Link></p>
                </Row>
              </div>
            </div>
            <div className="contact-testimonials">
              <Container>
                <Row>
                  <Testimonials />                
                </Row>
              </Container>
            </div>
                <div className="services-insta-header">
                    <Row className="insta-intro">
                        <Col className="center">
                          <h4>Check us out on Instagram</h4>
                        </Col>
                    </Row>
                    <Col className="services-insta-wrap">
                        <InstaHome />
                    </Col>
                </div>
        </Layout>
    )
}